






























































































































































































































































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { createCreative, createPayment, createRFQ } from '@/helpers/api';
import { required, email, minLength } from 'vuelidate/lib/validators';
import { loadStripe } from '@stripe/stripe-js';
import { LocaleMessages } from 'vue-i18n';
import listSVG from '@/assets/img/list.svg';
import cardSVG from '@/assets/img/credit-card.svg';
import eyeSVG from '@/assets/img/eye.svg';
import MobileBar from '@/components/MobileBar.vue';
import ProgressBar from '@/components/ProgressBar.vue';

import lottie from 'lottie-web';
import axios from 'axios';
import * as Sentry from '@sentry/vue';
import dayjs from 'dayjs';
import { updateSession } from '@/helpers/session';

interface Billing {
  email: string | null;
  fullName: string | null;
  phone: string | null;
  line1: string | null;
  line2: string | null;
  city: string | null;
  postcode: string | null;
  country: string | null;
  vat: string | null;
  siret: string | null;
  coupon: string | null;
}

@Component({
  components: {
    listSVG,
    cardSVG,
    eyeSVG,
    MobileBar,
    ProgressBar
  },
  validations: {
    billing: {
      email: { required, email },
      phone: { required, minLength: minLength(4) },
      fullName: {required, minLength: minLength(2) },
      line1: {required},
      city: {required},
      postcode: {required}
    }
  }
})
export default class Summary extends Vue {
  private stripe: any = null;
  private timer: any = null;
  private debounce = 300;
  private card: any = {};
  private orientation: 'portrait' | 'landscape' | null = null;
  private currentPage: 'preview' | 'summary' | 'pay' = 'summary';
  private paymentError: LocaleMessages | string | null = null;
  private cardNumberError = false;
  private type: 'private' | 'company' = 'company';
  private cgvAccepted = false;
  private anim: any = null;
  private amount: any = null;
  private errors: any = {};
  private couponToApply: string | null = null;
  private billing: Billing = {
    email: null,
    fullName: null,
    phone: null,
    line1: null,
    line2: null,
    city: null,
    postcode: null,
    country: this.$store.state.config?.checkout?.defaultCountry,
    vat: null,
    siret: null,
    coupon: null
  };

  private cardValid = {
    cvc: false,
    number: false,
    mmyy: false
  };

  private stopUpload() {
    this.$store.state.cancelTokenSource.cancel('Upload canceled');
    this.$store.state.progress = 0;
    this.$store.commit('removeMedia');
  }

  private get isCardValid() {
    return this.cardValid.cvc && this.cardValid.number && this.cardValid.mmyy;
  }

  private showInfo = false;

  private async validate() {
    this.$wait.start('validating');
    try {
      let creative: any = null;
      if (!this.$store.state.media) {
        creative = await createCreative({
          template_variant: this.$store.state.template.id,
          is_draft: false,
          fields: this.$store.state.templateFields.map((f: any) => {
            const temp = { ...f };
            delete temp.target;
            return temp;
          })
        });
      } else {
        creative = await createCreative({
          template_variant: null,
          is_draft: false,
          fields: { assets: this.$store.state.media.source }
        });
      }
      this.paymentError = null;
      await this.updateOrcreateIntent(true);
      if (!this.$store.getters['needRequestForQuote']) {
        const response = await this.stripe.confirmCardPayment(
          this.$store.state.stripeIntent.client_secret,
          {
            // eslint-disable-next-line @typescript-eslint/camelcase
            payment_method: {
              card: this.card.cardNumber,
              // eslint-disable-next-line @typescript-eslint/camelcase
              billing_details: {
                name: this.billing.fullName
              }
            }
          }
        );

        // CREATE INVOICE
        createPayment({
          billing: this.billing,
          url_location: window.location.origin,
          campaign: {
            screens: this.$store.state.screens,
            from: this.$store.state.fromDate,
            to: this.$store.state.toDate
          },
          creative: creative.data.id,
          provider: process.env.VUE_APP_BUILD,
          stripeIntent: this.$store.state.stripeIntent
        });

        if (response && response.data && response.data.error) {
          this.paymentError = this.$t(response.data.error.message);
        }
      } else {
        // rfq
        await createRFQ({
          screens: this.$store.state.screens,
          fromDate: this.$store.state.fromDate,
          toDate: this.$store.state.toDate,
          billing: this.billing,
          provider: process.env.VUE_APP_BUILD,
          creative: creative.data.id,
          creative_raw: creative
        });
        this.$store.commit('resetStore');
        await this.$router.push({ name: 'rfqSucceeded' });
        this.$wait.end('validating');

        return;
      }

      // reset store before rerouting
      this.$store.commit('resetStore');
      await this.$router.push({ name: 'paymentSucceeded' });
      this.$wait.end('validating');
    } catch (e) {
      this.$wait.end('validating');
      console.error(e);
      //this.paymentError = this.$t('summary.error_msg');
      //Sentry.captureException(e);
    }
  }

  private async updateOrcreateIntent(validate = false) {
    // if request for quote => don't update payment
    if (this.$store.getters['needRequestForQuote']) return;
    else if (this.$store.getters['getTotalPrice']) {
      try {
        const response = (
          await axios.post(`${process.env.VUE_APP_BACKEND_ENDPOINT}/stripe/paymentIntent`, {
            screens: this.$store.state.screens,
            fromDate: this.$store.state.fromDate,
            toDate: this.$store.state.toDate,
            intent: this.$store.state.stripeIntent ? this.$store.state.stripeIntent : null,
            validate,
            coupon: this.couponToApply,
            billing: this.billing,
            provider: process.env.VUE_APP_BUILD
          })
        ).data;
        this.amount = response.amount;
        this.$wait.end('loading-coupon');
        this.$store.commit('setStripeIntent', response.paymentIntent);
      } catch (e) {
        this.$wait.end('loading-coupon');
        Sentry.captureException(e);
      }
    }
  }

  private async applyCoupon() {
    this.$wait.start('loading-coupon');
    this.couponToApply = this.billing?.coupon;
    await this.updateOrcreateIntent();
  }

  private async clearCoupon() {
    this.couponToApply = null;
    await this.updateOrcreateIntent();
  }

  @Watch('billing', { deep: true })
  private async handleBilling() {
    const regexVAT = new RegExp('^[A-Za-z]{2,4}(?=.{2,12}$)[-_s0-9]*(?:[a-zA-Z][-_s0-9]*){0,2}$');
    if (this.billing.vat && this.billing.vat.length && !regexVAT.test(this.billing.vat)) {
      this.errors.vat = this.$t('_globals.vat_not_valid');
    } else {
      this.errors.vat = null;
    }
    if (this.timer) clearTimeout(this.timer);
    this.timer = setTimeout(async () => {
      await updateSession({ userInfo: { name: this.billing.fullName, email: this.billing.email } });
      await this.updateOrcreateIntent();
    }, this.timer);

    this.$store.commit('setUserInfo', {
      name: this.billing.fullName,
      email: this.billing.email
    });
  }
  private changeCurrentMobileView() {
    this.currentPage = 'preview';
    setTimeout(() => this.play(), 50);
  }

  private play() {
    lottie.stop();
    lottie.destroy();
    if (this.$store.state.template) {
      this.anim = lottie.loadAnimation({
        container: this.$refs.preview as Element,
        renderer: 'svg',
        animationData: this.$store.state.template.json_data,
        loop: true,
        autoplay: true
      });
      lottie.play();
    }
  }

  private async created() {
    updateSession({
      currentPage: this.$route.name
    });
    if (this.$store.state.adType === 'private') this.type = 'private';

    try {
      await this.updateOrcreateIntent();
    } catch (e) {
      console.error("Creating intent didn't work", e);
    }

    this.orientation =
      this.$store.state?.template?.orientation === 'landscape' ? 'landscape' : 'portrait';
    this.billing = {
      ...this.billing,
      fullName: this.$store.state.userInfo.name,
      email: this.$store.state.userInfo.email
    };
  }

  private get hasCreative() {
    return this.$store.state.template || this.$store.state.media;
  }

  private async mounted() {
    if (this.$store.state.template) this.play();
    // if there is not request for quote => instant bill
    if (!this.$store.getters['needRequestForQuote']) {
      try {
        this.stripe = await loadStripe(this.$store.state.stripe);
      } catch (e) {
        Sentry.captureException(e);
      }

      const classes = {
        focus: 'relative border px-3 block py-3 rounded-md box-border mb-3',
        empty: 'relative border px-3 block py-3 rounded-md box-border mb-3',
        invalid: 'relative border px-3 block py-3 rounded-md box-border border-red-400',
        complete: 'relative border px-3 block py-3 rounded-md box-border mb-3'
      };

      const style = {};
      try {
        const elements = this.stripe.elements();
        this.card.cardNumber = elements.create('cardNumber', {
          style,
          classes: { ...classes }
        });
        this.card.cardNumber!.mount('#card-number');
        this.card.cardExpiry = elements.create('cardExpiry', {
          style,
          classes
        });
        this.card.cardExpiry!.mount('#card-expiry');
        this.card.cardCvc = elements.create('cardCvc', {
          style,
          classes
        });
        this.card.cardCvc!.mount('#card-cvc');
        this.card.cardCvc.on('change', (event: any) => {
          this.cardValid.cvc = event.complete;
        });
        this.card.cardExpiry.on('change', (event: any) => {
          this.cardValid.mmyy = event.complete;
        });
        this.card.cardNumber.on('change', (event: any) => {
          this.cardValid.number = event.complete;
        });
        this.card.cardNumber.on('blur', (event: any) => {
          !this.cardValid.number ? (this.cardNumberError = true) : (this.cardNumberError = false);
        });
        this.updateOrcreateIntent();
      } catch (e) {
        Sentry.captureException(e);
      }
    }
  }
}
